import { Component, ErrorHandler, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform, MenuController, AlertController } from '@ionic/angular';
import { importsStandalone } from 'src/app/05-shared/utils/ImportsStandalone';
import { Router, RouterEvent, NavigationEnd, RouterOutlet } from '@angular/router';
import { BackgroundRunner } from '@capacitor/background-runner';
import { PushNotifications, Token, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';

import {
  AuthService,
  LoadingService,
  SynchronizationService,
  ToastService,
  WorkplaceService,
} from '../02-services';

import { Workplace, User } from '../01-models';
import { OfflineManagerService } from '../02-services/offline-manager.service';
import { StorageService } from '../02-services/storage.service';
import { environment } from 'src/environments/environment';
import { NetworkService, ConnectionStatus } from '../02-services/network.service';
import { forkJoin } from 'rxjs';
import { IonRouterOutlet } from "@ionic/angular/standalone";
import { Device } from '@capacitor/device';

const DATA_SYNC_TIME_MINUTES = 1;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    IonRouterOutlet,
    RouterOutlet
  ],
  providers: [
  ]
})
export class AppComponent implements OnInit {
  pages = [
    {
      name: 'presence-list',
      title: 'PRESENCES.TITLE',
      url: '/tabs/presence-list',
      icon: 'time',
    },
    {
      name: 'service-sv-list',
      title: 'SERVICE_CONTRACT_LIST.TITLE',
      url: '/service-sv-list',
      icon: 'clipboard',
    },
    {
      name: 'cpd-list',
      title: 'CPD_LIST.TITLE',
      url: '/cpd-list',
      icon: 'clipboard',
    },

    {
      name: 'workplaces',
      title: 'WORKPLACES.TITLE',
      url: '/workplaces',
      icon: 'business',
    },
    {
      name: 'dependence-list',
      title: 'DEPENDENCE_LIST.TITLE',
      url: '/dependence-list',
      icon: 'business',
    },
    {
      name: 'control-check',
      title: 'CONTROL_CHECK.TITLE',
      url: '/control-check',
      icon: 'time',
    },
    {
      name: 'map',
      title: 'CONTROL_CHECK.TITLE',
      url: '/map',
      icon: 'location',
    },

  ];

  isAuthenticated: boolean = false;
  user: User;
  workplace: Workplace;
  versionNumber: string;
  versionDate: string;
  presenceService: any;
  expectedPresenceService: any;
  versionAppNumber: string;
  versionApiNumber: string;
  publicationAppDate: string;
  publicationApiDate: string;
  userTest: any = null;
  deviceId: string = '';

  constructor(
    private platform: Platform,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private alertCtrl: AlertController,
    private menuCtrl: MenuController,
    private offlineManager: OfflineManagerService,
    private syncService: SynchronizationService,
    private storageService: StorageService,
    private networkService: NetworkService,
    public loadingService: LoadingService,
    public toastService: ToastService,
    public workplaceService: WorkplaceService
  ) {

    this.publicationAppDate = environment.versionDate;
    this.versionAppNumber = environment.versionNumber;

  }
  ngOnInit() {
    this.initializeApp();
    //this.loadVersionAPI();    

    Device.getInfo().then(info => {
      console.log("Plataforma:", info.platform);
      if (info.platform !== 'web') {

        // Solicitar permisos para notificaciones push
        PushNotifications.requestPermissions().then(permission => {
          if (permission.receive === 'granted') {
            // Registrar el dispositivo
            PushNotifications.register();
          }
        });

        // Escuchar el token de registro
        PushNotifications.addListener('registration', (token: Token) => {
          console.log('Token de notificaciónes push:', token.value);
          this.deviceId = token.value;
          this.storageService.set('deviceToken', this.deviceId);
        });

        // Manejar errores de registro
        PushNotifications.addListener('registrationError', (error: any) => {
          console.error('Error de registro de notificación push:', error);
        });

        // Escuchar notificaciones push recibidas en primer plano
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
          console.log('Notificación recibida: ', JSON.stringify(notification));
          if (notification.data.action === 'showAlert') {
            this.showAlert(notification.data.title, notification.data.body);
          }
        });

        // Manejar acciones de notificaciones push cuando está cerrada la app
        PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
          console.log('Acción de notificación:', JSON.stringify(notification));
          this.HandleNotification(notification.notification.data);  // Puedes enviar datos personalizados en "data"
        });
      }
    });


    if (this.authService.isAuthenticated()) {
      // this.router.navigate(['tabs/presence-list'], { replaceUrl: true });
    } else
      this.router.navigate(['login'], { replaceUrl: true });
  }

  async showAlert(title: string, body: string) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: body,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => { },
        },
      ],
    });

    await alert.present();
  }

  HandleNotification(notification: any) {
    // Maneja la notificación dependiendo de lo que venga en los datos (payload)
    if (notification.data && notification.data.action) {
      // Ejecuta la acción que quieras. Por ejemplo, navegar a una página:
      console.log('Navegar a una página específica: ', notification.data.page);
      switch (notification.data.action) {
        case 'openPage':
          this.router.navigate([notification.data.page]);
          break;
        case 'showAlert':
          this.showAlert(notification.data.title, notification.data.body);

          break;

      }

    }

  }
  presentAlert(title: any, body: any) {
    throw new Error('Method not implemented.');
  }


  async testSave() {
    const result = await BackgroundRunner.dispatchEvent({
      label: 'com.kflow.check',
      event: 'testSave',
      details: {}
    });
    console.log('App.component.ts - testSave - result', result);
  }

  async testLoad() {
    const result = await BackgroundRunner.dispatchEvent({
      label: 'com.kflow.check',
      event: 'testLoad',
      details: {}
    });
    console.log('App.component.ts - testLoad - result', result);
  }

  async performBackgroundFetch() {
    const result = await BackgroundRunner.dispatchEvent({
      label: 'com.kflow.check',
      event: 'fetchTest',
      details: {},
    });
    this.userTest = result;
  }

  async scheduleNotification() {
    await BackgroundRunner.dispatchEvent({
      label: 'com.kflow.check',
      event: 'notificationTest',
      details: {}
    });
  }


  initializeApp() {
    this.platform.ready().then(() => {
      ////console.log('esto es:');
      ////console.log(this.platform);
      setInterval(this.loadData.bind(this), DATA_SYNC_TIME_MINUTES * 120 * 1000);

      this.translate.addLangs(['es', 'cat', 'en', 'de']);
      this.translate.setDefaultLang('es');

      //let langJson = localStorage.getItem('lang');
      this.storageService.get('lang').then(language => {
        if (language) {
          const initialLang = language ? language : 'es';

          this.translate
            .use('es')
            .subscribe(() =>
              this.translate
                .use('cat')
                .subscribe(() =>
                  this.translate
                    .use('en')
                    .subscribe(() =>
                      this.translate
                        .use('de')
                        .subscribe(() => this.translate.use(initialLang || 'es'))
                    )
                )
            );
        }
      });
    });
  }

  loadData() {
    if (this.isAuthenticated) {
      this.offlineManager
        .checkForEvents({ forceSync: false, showError: false })
        .subscribe(() => {          
            this.syncService.sendSyncByTable();                    
        });
    }

  }

  forceSync() {
    if (
      this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Online
    ) {
      this.loadingService.present();
      this.offlineManager
        .checkForEvents({ forceSync: true, showError: false })
        .subscribe(
          () => {
            this.syncService.sendSyncByTable();
            this.loadingService.closeLoading();
          },
          (error) => {
            this.syncService.sendSyncByTable();
            this.loadingService.closeLoading();
          }
        );
    } else {
      this.toastService.error('No tienes conexión a internet.');
    }
  }

  loadVersionAPI() {
    this.networkService.getVersionAPI().subscribe((version) => {
      this.versionApiNumber = version.version;
      this.publicationApiDate = version.date;
    });
  }

  login() {
    this.router.navigate(['login']);
  }

  logout() {
    this.storageService.get('storedreq').then((storedObj) => {
      if (storedObj && storedObj.length > 0) {
        this.presentAlertSyncPending();
      } else {
        this.authService.logout().then(() => {
          this.menuCtrl.toggle();
          this.router.navigate(['login'], { replaceUrl: true });
        });
      }
    });
  }

  async presentAlertSyncPending() {
    const alert = await this.alertCtrl.create({
      header: 'Pendiente sincronización',
      message:
        'Al cerrar sesión se perderan los datos pendientes de sincronización. ¿ Seguro que quieres cerrar sesión ?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { },
        },
        {
          text: 'Cerrar sesión',
          handler: () => {
            this.authService.logout().then(() => {
              this.menuCtrl.toggle();
              // this.router.navigate(['login'], {replaceUrl: true});
            });
          },
        },
      ],
    });
    alert.present();
  }

  editProfile() {
    this.router.navigate(['profile']).then(() => this.menuCtrl.toggle());
  }

  isVisible(page) {
    // if (page.name === 'workplaces') {
    //   return this.user.isAdmin;
    // } else if (page.name === 'workplaces-selection') {
    //   return !this.user.isAdmin;
    // }
    const adminPages = [
      'presence-list',
      'service-sv-list',
      'service-extra-list',
      'cpd-list',
      'workplaces',
      'dependence-list',
      'worker-list',
      'control-check',
      'map'
    ];
    const ClientePages = [
      'service-sv-list',
      'service-extra-list',
      'dependence-list',
      'control-check',
      'map'
    ];
    const leadPages = [
      'presence-list',
      'service-sv-list',
      'service-extra-list',
      'dependence-list',
      'worker-list',
      'control-check',
      'map'
    ];
    const workerPages = [
      'presence-list',
      'service-sv-list',
      'service-extra-list',
      'control-check',
      'map'
    ];
    const commonPages = ['settings'];
    if (
      commonPages.includes(page.name) ||
      (this.user?.role === 'Administrador' && adminPages.includes(page.name)) ||
      (this.user?.role === 'Responsable' && leadPages.includes(page.name)) ||
      (this.user?.role === 'Operario' && workerPages.includes(page.name)) ||
      (this.user?.role === 'Cliente' && ClientePages.includes(page.name))
    ) {
      return true;
    }
    return false;
  }

  setRedirections() { }

  isProdEnvironment() {
    return environment.production;
  }
}
