import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/02-services';

export const permissionGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);
  return lastValueFrom(userService.getPoliciyPrivacy()).then((permissions) => {
    if (permissions) {
      if (permissions.basicPermissions) {
        return true;
      } else {
        router.navigate(['/privacy-policy']);
        return false;
      }
    } else {
      router.navigate(['/privacy-policy']);
      return false;
    }
    
  });
  
};
